import { MobileMenuButtonTypes } from '../types';
import { Button } from '@aurora-components';
import { CloseMenuIcon, MobileMenuIcon } from 'icons/components';

export const MobileMenuButton = ({ $isOpen, onClick }: MobileMenuButtonTypes) => {
  return (
    <Button
      as="button"
      $variant="primary"
      onClick={onClick}
      data-testid="mobile-nav-menu-button"
    >
      {$isOpen ? <CloseMenuIcon /> : <MobileMenuIcon />}
    </Button>
  );
};
