import Link from 'next/link';

import * as Popover from '@radix-ui/react-popover';
import { CloseMenuIcon } from 'icons/components';
import styled, { css } from 'styled-components';

import { Colors } from 'styles/enums';
import { mediaQueries } from 'styles/helpers';

export const ContentWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Yellow.base100};
  border-radius: 0 5px 5px 5px;
  margin-left: 5px;

  a {
    min-width: 100px;
  }

  ${mediaQueries(
    'md',
    css`
      margin-top: 0;
      margin-bottom: 0;
    `
  )};
`;

export const SelectedLanguage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  svg {
    padding: 5px;
    background-color: ${Colors.Yellow.base100};
    border-radius: 100%;
  }
  h4 {
    font-size: 24px;
  }
`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  selected?: boolean;
}

export const PickerButton = styled(Link)<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  border: none;
  &:hover {
    background-color: ${Colors.Secondary.lightBlue.base100};
  }

  svg {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  }
`;

export const OpenPickerButtonDesktop = styled.button`
  border: none;
  background-color: ${Colors.UI.beigeWhite};
  cursor: pointer;
  display: none !important;
  ${mediaQueries(
    'lg',
    css`
      display: flex !important;
    `
  )};
`;

export const OpenPickerButtonMobile = styled.button`
  border: none;
  background-color: ${Colors.UI.beigeWhite};
  width: 100%;
  cursor: pointer;
  ${mediaQueries(
    'lg',
    css`
      display: none;
    `
  )};
`;

export const PopoverArrow = styled(Popover.Arrow)`
  fill: ${Colors.Yellow.base100};
  margin-left: 5px;
`;

export const StyledPopoverTrigger = styled(Popover.Trigger)`
  display: none;
  ${mediaQueries(
    'md',
    css`
      display: flex;
    `
  )};
`;

export const StyledPopoverPortal = styled(Popover.Portal)`
  display: none;
  ${mediaQueries(
    'md',
    css`
      display: flex;
    `
  )};
`;

export const MobileLanguagePicker = styled.div`
  margin-top: 40px;
  display: block;

  ${mediaQueries(
    'lg',
    css`
      display: none;
    `
  )};
`;

export const MobileLanguageSelect = styled.div`
  position: absolute;
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${Colors.Yellow.base100};

  svg {
    align-self: center;
  }
`;

interface ClosePickerProps {
  onClick: () => void;
}
export const CloseLanguagePicker = styled(CloseMenuIcon)<ClosePickerProps>`
  align-self: flex-end !important;
  cursor: pointer;
`;
