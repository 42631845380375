'use client';

import { useState } from 'react';

import { useParams } from 'next/navigation';

import { NavigationMenuFragment } from './navigation-menu-gql';
import { Header, NavContainer, NavMobileMenuButtonContainer } from './navigation-menu.styles';
import { MobileMenuButton } from './partials/mobile-menu-button';
import { NavContent } from './partials/nav-content';
import { FragmentType } from '@gql';

type Props = {
  locale: string;
} & FragmentType<typeof NavigationMenuFragment>;
export const NavigationMenu = ({ locale, ...props }: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const { slug } = useParams() as { slug: string };

  return (
    <Header>
      <NavContainer>
        <NavContent
          $isOpen={mobileMenuOpen}
          locale={locale}
          slug={slug}
          onClick={() => setMobileMenuOpen(false)}
          {...props}
        />

        <NavMobileMenuButtonContainer>
          <MobileMenuButton
            $isOpen={mobileMenuOpen}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          />
        </NavMobileMenuButtonContainer>
      </NavContainer>
    </Header>
  );
};
