import { SysAssetConnectionFragment } from '../lib/shared/sys-asset-connection-fragment-gql';
import { FragmentType, getFragmentData } from '@gql';

type SysAssetConnection = FragmentType<typeof SysAssetConnectionFragment>;
const imageSuffix: { [key: string]: string } = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
};
//https://cloudinary.com/documentation/image_transformations#landingpage
const getImage = (props: SysAssetConnection | null | undefined) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const asset = getFragmentData(SysAssetConnectionFragment, props);
  if (asset?.edges?.length && asset?.edges?.length > 0) {
    if (asset.edges[0]?.node) {
      const { content_type, system, description, dimension } = asset.edges[0].node;

      if (!content_type || !system || !dimension) {
        return null;
      } else {
        return {
          assetName: `${system?.uid}.${imageSuffix[content_type]}`,
          description: description ? description : '',
          dimension,
          id: system?.uid,
          suffix: content_type,
        };
      }
    }
  }
  return null;
};
// * todo: use e_blur transformation
// https://pixels-cache.icelandair.com/upload/w_64,e_blur/icelandair/bltad3f5664e9d97581.jpg
const blurImageLoader = (src: string) => {
  return `https://pixels-cache.icelandair.com/upload/w_16,h_16/icelandair/${src}`;
};

export { getImage, blurImageLoader };
