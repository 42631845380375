import { graphql } from '@gql';

export const SysAssetConnectionFragment = graphql(/* GraphQL */ `
  fragment SysAssetConnectionFragment on SysAssetConnection {
    __typename
    edges {
      node {
        content_type
        description
        dimension {
          height
          width
        }
        system {
          uid
        }
      }
    }
    totalCount
  }
`);
