import Link from 'next/link';

import { NavContentTypes, NavLinkTypes } from './types';
import styled, { css } from 'styled-components';

import { Sizes } from 'styles/constants';
import { Colors, FontSize, FontSizePx, FontWeight } from 'styles/enums';
import { generateHighlight, mediaQueries } from 'styles/helpers';
import { HEADER_HEIGHT } from 'styles/tokens/spacing';

export const Header = styled.header`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  padding: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 10;
  background-color: ${Colors.UI.beigeWhite};

  ${mediaQueries(
    'lg',
    css`
      padding: 0px 30px;
    `
  )}
`;

export const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 2px;
  padding-right: 0px;
  max-width: ${Sizes.maxWidth}px;
  margin: 0 auto;

  ${mediaQueries(
    'lg',
    css`
      flex-wrap: nowrap;
      padding-right: 18px;
      column-gap: 16px;
      align-items: center;
    `
  )}

  ${mediaQueries(
    'xxl',
    css`
      padding-left: 15px;
      padding-right: 80px;
    `
  )}
`;

export const NavLogoContainer = styled.div<{ height: number }>`
  display: flex;
  height: ${({ height }) => height}px;
  margin-top: 32px;
  padding: 0px 8px;

  div {
    width: 110px;
    height: 100%;
  }
`;

export const NavLinksContainer = styled.div<NavContentTypes>`
  width: 100%;
  height: calc(100dvh - ${HEADER_HEIGHT}px);
  ${({ $isOpen }) => `display: ${$isOpen ? 'flex' : 'none'};`}
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  order: 3;
  gap: ${Sizes.gutter}px;
  margin-top: -2px;
  padding-top: 0;
  background-color: ${Colors.UI.beigeWhite};
  overflow: scroll;

  a {
    color: ${Colors.Primary.base100};
    font-size: ${FontSizePx.navlink};
    font-weight: ${FontWeight.Medium};
    padding: 12px 24px;

    ${mediaQueries(
      'lg',
      css`
        color: ${Colors.Gray.base};
        font-size: ${FontSize.Big};
        padding: 0px;
      `
    )}
  }

  a.nav-button {
    display: flex;
    justify-content: center;
    min-width: 210px;
    padding: 12px 24px;
    font-size: ${FontSize.XLarge};

    ${mediaQueries(
      'lg',
      css`
        min-width: 97px;
        padding: 6px 16px;
        font-size: ${FontSize.Default};
      `
    )}
  }

  ${mediaQueries(
    'lg',
    css`
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: ${Sizes.gutterSmall}px;
      margin-top: 56px;
      padding-top: 0;
      padding-bottom: 16px;
      z-index: 1;
      overflow: hidden;
      padding-right: 0;
    `
  )}

  ${mediaQueries(
    'xxl',
    css`
      gap: ${Sizes.gutter}px;
    `
  )}
`;

export const NavMobileMenuButtonContainer = styled.div`
  display: flex;
  padding-right: 35px;
  margin-top: 50px;
  justify-content: flex-end;
  align-items: center;
  order: 2;

  button {
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  ${mediaQueries(
    'lg',
    css`
      display: none;
    `
  )}
`;

export const NavLink = styled(Link)<NavLinkTypes>`
  position: relative;
  pointer-events: ${({ $isActive }) => ($isActive ? 'none' : 'auto')};
  ${({ $isActive }) => generateHighlight($isActive)}
`;
