export interface FlagProps {
  height?: number;
  'data-testid': string;
}

const SvgComponent = ({ height = 7, ...props }: FlagProps) => (
  <svg
    width="6"
    height={height}
    viewBox="0 0 6 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="3.19153"
      cy="3.37952"
      r="2.62991"
      fill="#50E68C"
      stroke="#001B71"
      strokeWidth="0.3"
    />
  </svg>
);

export default SvgComponent;
