import Link from 'next/link';

import { NavigationMenuFragment } from '../navigation-menu-gql';
import { NavLink, NavLinksContainer, NavLogoContainer } from '../navigation-menu.styles';
import { ButtonType, ButtonsList, LinkType, LinksList, NavContentTypes } from '../types';
import { Button, Image } from '@aurora-components';
import { FragmentType, getFragmentData } from '@gql';
import { LogoIcon } from 'icons/components';
import { getImage } from 'utils/get-image';

import { CloudinaryCropResize, CloudinaryGravity } from 'components/aurora/image/types';
import LanguagePicker from 'components/aurora/language-picker/language-picker';

const getUrl = (url: string) => {
  if (url.includes('https')) return url;
  if (url.includes('www')) return `https://${url}`;
  return '/';
};

const getNavigationItemsList = (
  links: LinkType[] | ButtonType[],
  locale: string,
  slug: string,
  buttons?: boolean,
  closeMenu?: () => void
) =>
  links.map((item: LinkType | ButtonType, index: number) => {
    if ((item as ButtonType)?.language_picker) {
      return <LanguagePicker key={index} />;
    }

    if (!buttons) {
      const { navigation_title, url } = (item as LinkType).node;
      return (
        <NavLink
          key={index}
          href={`${locale === 'en' ? '/en' : ''}${url}`}
          $isActive={url === `/${slug}`}
          onClick={closeMenu}
          tabIndex={index}
        >
          {navigation_title}
          <span />
        </NavLink>
      );
    }

    const button = item as ButtonType;
    const isInternalConnection = !!button.cta?.internal_linkConnection?.edges?.length;
    const linkUrl = isInternalConnection
      ? `${locale === 'en' ? '/en' : ''}${button.cta?.internal_linkConnection?.edges![0]?.node?.url}`
      : `${getUrl(button.cta?.external_link?.href || '')}`;

    return (
      <Button
        key={index}
        as="a"
        $variant={button.cta?.button_style}
        href={linkUrl}
        target={isInternalConnection ? '_self' : '_blank'}
        className="nav-button"
        onClick={closeMenu}
        tabIndex={index}
      >
        {button.cta?.label}
      </Button>
    );
  });

type Props = NavContentTypes &
  FragmentType<typeof NavigationMenuFragment> & {
    locale: string;
    slug: string;
  };

export const NavContent = ({ $isOpen, locale, slug, onClick, ...props }: Props) => {
  const vildar_navigation = getFragmentData(NavigationMenuFragment, props);

  if (vildar_navigation === null) return null;

  const { assetConnection, alt_text, gravity, crop_resize } = vildar_navigation?.logo || {};
  const imageData = getImage(assetConnection);
  const linksList = vildar_navigation?.pagesConnection as LinksList;
  const buttonsList = vildar_navigation?.buttons as ButtonsList;
  const { width, height } = imageData?.dimension || {};
  const ratio = width && height ? height / width : 16 / 9;

  return (
    <>
      <NavLogoContainer height={110 * ratio}>
        <Link
          href={locale === 'en' ? '/en' : '/'}
          onClick={onClick}
          prefetch={false}
        >
          {vildar_navigation?.logo ? (
            <Image
              imageId={imageData?.id || ''}
              priority={true}
              alt={alt_text || ''}
              withAspectRatio={true}
              width={imageData?.dimension?.width}
              height={imageData?.dimension?.height}
              imageSuffix={imageData?.suffix || ''}
              gravity={gravity as CloudinaryGravity}
              crop_resize={crop_resize as CloudinaryCropResize}
            />
          ) : (
            <LogoIcon />
          )}
        </Link>
      </NavLogoContainer>

      <NavLinksContainer $isOpen={$isOpen}>
        {getNavigationItemsList(Object.values(linksList.edges), locale, slug, false, onClick)}
        {getNavigationItemsList(buttonsList, locale, slug, true, onClick)}
      </NavLinksContainer>
    </>
  );
};
