import { graphql } from '@gql';

/**
 * batched combo of fetching the navigation and footer menu
 */
export const getNavigationAndFooterByLocaleQueryDocument = graphql(/* GraphQL */ `
  query NavigationAndFooter($navId: String!, $footerId: String!, $locale: String!) {
    navigation: vildar_navigation(uid: $navId, locale: $locale, fallback_locale: true) {
      ...NavigationMenuFragment
    }
    footer: vildar_navigation(uid: $footerId, locale: $locale, fallback_locale: true) {
      ...NavigationAndFooterFragment
    }
  }
`);
/**
 * added $footer query, this enables us to conditionally request the footer specific data
 */
export const getNavigationMenuByLocaleQueryDocument = graphql(/* GraphQL */ `
  query NavigationMenu($uid: String!, $locale: String!, $footer: Boolean!) {
    vildar_navigation(uid: $uid, locale: $locale, fallback_locale: true) {
      ...NavigationMenuFragment
      footer_specific @include(if: $footer) {
        ...FooterSpecificFragment
      }
    }
  }
`);

export const NavigationAndFooterFragment = graphql(/* GraphQL */ `
  fragment NavigationAndFooterFragment on VildarNavigation {
    ...NavigationMenuFragment
    footer_specific {
      ...FooterSpecificFragment
    }
  }
`);

export const NavigationMenuFragment = graphql(/* GraphQL */ `
  fragment NavigationMenuFragment on VildarNavigation {
    buttons {
      ... on VildarNavigationButtonsLanguagePicker {
        __typename
        language_picker {
          languages
        }
      }
      ... on VildarNavigationButtonsCta {
        __typename
        cta {
          button_style
          external_link {
            href
            title
          }
          internal_linkConnection {
            edges {
              node {
                ... on VildarPage {
                  navigation_title
                  url
                }
              }
            }
          }
          label
        }
      }
    }

    links {
      ... on VildarNavigationLinksCta {
        __typename
        cta {
          external_link {
            href
            title
          }
          label
          internal_linkConnection {
            edges {
              node {
                ... on VildarPage {
                  navigation_title
                  url
                }
              }
            }
          }
        }
      }
    }
    logo {
      alt_text
      assetConnection {
        ...SysAssetConnectionFragment
      }
      crop_resize
      gravity
    }
    pagesConnection {
      edges {
        node {
          ... on VildarPage {
            navigation_title
            url
          }
        }
      }
    }
    title
  }
`);
export const LanguagePickerFragment = graphql(/* GraphQL */ `
  fragment LanguagePickerFragment on VildarNavigationButtonsLanguagePicker {
    __typename
    language_picker {
      languages
    }
  }
`);

export const FooterSpecificFragment = graphql(/* GraphQL */ `
  fragment FooterSpecificFragment on VildarNavigationFooterSpecific {
    headline
    email
    icelandair_logo {
      assetConnection {
        edges {
          node {
            system {
              uid
            }
            content_type
            dimension {
              height
              width
            }
          }
        }
      }
      crop_resize
      gravity
    }
    impressum
    phone
  }
`);
// export const NavigationButtonsCtaFragment = graphql(/* GraphQL */ `
//   fragment NavigationButtonsCtaFragment on VildarNavigationButtonsCta {
//     __typename
//     cta {
//       button_style
//       external_link {
//         href
//         title
//       }
//       internal_linkConnection {
//         edges {
//           node {
//             ... on VildarPage {
//               navigation_title
//               url
//             }
//           }
//         }
//       }
//       label
//     }
//   }
// `);

// export const VildarNavigationLinksCtaFragment = graphql(/* GraphQL */ `
//   fragment NavigationLinksCtaFragment on VildarNavigationLinksCta {
//     __typename
//     cta {
//       external_link {
//         href
//         title
//       }
//       label
//       internal_linkConnection {
//         edges {
//           node {
//             ... on VildarPage {
//               navigation_title
//               url
//             }
//           }
//         }
//       }
//     }
//   }
// `);
